import classnames from "classnames";
import FeatherIcon from "feather-icons-react";
import _ from "lodash";
import React from "react";
import { ChevronDown, ChevronRight } from "react-feather";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

/**
 * Represents a horizontal sidebar component in a React application.
 * Manages the state for active parents, open dropdowns, and item hover.
 * Provides methods for handling dropdowns, item hover, and active parents.
 * Renders submenus and dropdown items based on the provided navigation configuration.
 */
class HorizontalSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeParents: [],
      openDropdown: [],
      itemHover: null,
      parentHover: null,
    };
    this.activeFlag = false;
    this.parentItems = [];
    this.activeParentItems = [];
  }

  openDropdown = (id) => {
    let arr = this.state.openDropdown;
    if (!arr.includes(id)) arr.push(id);
    this.setState({
      openDropdown: arr,
    });
  };

  closeDropdown = (id) => {
    let arr = this.state.openDropdown;
    arr.splice(arr.indexOf(id), 1);
    this.setState({
      openDropdown: arr,
    });
  };

  handleItemHover = (id) => {
    this.setState({
      itemHover: id,
    });
  };

  handleParentHover = (id) => {
    this.setState({
      parentHover: id,
    });
  };

  handleActiveParent = (arr) => {
    this.setState({
      activeParents: arr,
    });
    this.activeFlag = false;
  };

  componentDidMount() {
    this.handleActiveParent(this.activeParentItems);
  }

  componentDidUpdate(prevProps) {
    if (this.props.activePath !== prevProps.activePath) {
      this.setState({ openDropdown: [], parentHover: null });
      this.handleActiveParent(this.activeParentItems);
    }
  }

  updateParentItems = (id, mainParent = false) => {
    if (mainParent === true) {
      this.parentItems = [];
    }
    if (!this.parentItems.includes(id)) {
      this.parentItems.push(id);
    }
    if (this.activeFlag === true) {
      this.activeParentItems = this.parentItems;
      this.parentItems = [];
      this.activeFlag = false;
    }
  };

  renderSubMenu = (submenu, id) => {
    return (
      <DropdownMenu
        tag="ul"
        className="mt-50"
        onMouseEnter={(e) => e.preventDefault()}
      >
        {submenu.map((child) => {
          if (child.visible === 0) {
            return null;
          }
          const CustomAnchorTag = child.type === "external-link" ? `a` : Link;
          if (child.navLink && child.navLink === this.props.activePath) {
            this.activeFlag = true;
            this.updateParentItems(id);
          }
          let renderChildItems = (
            <React.Fragment key={child.id}>
              <li
                className={classnames({
                  active: this.state.activeParents.includes(child.id),
                })}
              >
                <DropdownItem
                  className={classnames("w-100", {
                    hover: this.state.itemHover === child.id,
                    "has-sub": child.children,
                    active:
                      (child.parentOf &&
                        child.parentOf.includes(this.props.activePath)) ||
                      (child.navLink &&
                        child.navLink === this.props.activePath),
                    "has-active-child": this.state.openDropdown.includes(
                      child.id
                    ),
                    disabled: child.disabled,
                  })}
                  tag={child.navLink ? CustomAnchorTag : "div"}
                  to={
                    child.filterBase
                      ? child.filterBase
                      : child.navLink && child.type === "item"
                      ? child.navLink
                      : "#"
                  }
                  href={
                    child.type === "external-link" ? child.navLink : undefined
                  }
                  target={child.newTab ? "_blank" : undefined}
                  onMouseEnter={() => this.handleItemHover(child.id)}
                  onMouseLeave={() => this.handleItemHover(null)}
                >
                  {child.children ? (
                    <Dropdown
                      className={classnames("sub-menu w-100", {})}
                      isOpen={this.state.openDropdown.includes(child.id)}
                      direction={"right"}
                      toggle={() => true}
                      onMouseEnter={() => this.openDropdown(child.id)}
                      onMouseLeave={() => this.closeDropdown(child.id)}
                    >
                      <DropdownToggle
                        className="d-flex justify-content-between align-items-center item-content"
                        tag={"div"}
                        onClick={() => this.closeDropdown(child.id)}
                      >
                        <div className="dropdown-toggle-sub text-truncate">
                          <span className="menu-icon align-bottom mr-1">
                            {child.icon}
                          </span>
                          <span className="menu-title align-middle">
                            {child.title}
                          </span>
                        </div>
                        <ChevronRight
                          className="has-sub-arrow align-middle ml-50"
                          size={15}
                        />
                      </DropdownToggle>
                      {child.children
                        ? this.renderSubMenu(child.children, child.id)
                        : null}
                    </Dropdown>
                  ) : (
                    <div className="item-content">
                      <span className="menu-icon align-top mr-1">
                        {child.icon}
                      </span>
                      <span className="menu-title align-middle">
                        {child.title}
                      </span>
                    </div>
                  )}
                </DropdownItem>
              </li>
            </React.Fragment>
          );
          if (
            child.type === "external-link" ||
            child.type === "dropdown" ||
            child.permissions.length === 0
          ) {
            return renderChildItems;
          } else {
            return null;
          }
        })}
      </DropdownMenu>
    );
  };

  renderDropdown = (arr) => {
    return arr.map((item) => {
      if (
        item.type === "item" &&
        item.navLink &&
        item.navLink === this.props.activePath
      ) {
        this.activeFlag = true;
        this.updateParentItems(item.id, true);
      }
      const CustomAnchorTag = item.type === "external-link" ? `a` : Link;
      return (
        <li
          className={classnames("nav-item", {
            active: this.state.activeParents.includes(item.id),
            hover: this.state.parentHover === item.id,
          })}
          key={item.id}
          ref={(el) => (this.menuDrodpown = el)}
        >
          <div
            className={classnames("nav-item-wrapper cursor-pointer", {
              "single-item": item.type === "item",
            })}
            onMouseEnter={() => {
              this.openDropdown(item.id);
              this.handleParentHover(item.id);
            }}
            onMouseLeave={() => {
              this.closeDropdown(item.id);
              this.handleParentHover(null);
            }}
          >
            {item.children ? (
              <Dropdown
                isOpen={this.state.openDropdown.includes(item.id)}
                className="nav-link"
                toggle={() => this.openDropdown(item.id)}
              >
                <DropdownToggle className="d-flex align-items-center" tag="div">
                  <div className="dropdown-text">
                    <span className="menu-icon align-middle mr-75">
                      {item.icon}
                    </span>
                    <span className="menu-title align-middle">
                      {item.title}
                    </span>
                  </div>
                  <ChevronDown className="ml-50 align-bottom" size={15} />
                </DropdownToggle>

                {this.updateParentItems(item.id, true)}
                {item.children
                  ? this.renderSubMenu(item.children, item.id)
                  : null}
              </Dropdown>
            ) : (
              <CustomAnchorTag
                className={classnames({
                  "nav-link": item.type === "item",
                  hover: this.state.parentHover === item.id,
                })}
                to={
                  item.filterBase
                    ? item.filterBase
                    : item.navLink && item.type === "item"
                    ? item.navLink
                    : "#"
                }
                href={item.type === "external-link" ? item.navLink : undefined}
                target={item.newTab ? "_blank" : undefined}
              >
                <span className="menu-icon align-middle mr-75">
                  {item.icon}
                </span>
                <span className="menu-title align-middle">{item.title}</span>
              </CustomAnchorTag>
            )}
          </div>
        </li>
      );
    });
  };

  render() {
    return (
      <div className="horizontal-menu-wrapper">
        <div
          className={classnames(
            "header-navbar navbar-expand-sm navbar navbar-horizontal navbar-shadow",
            {
              "navbar-static": this.props.navbarType === "static",
              "fixed-top": this.props.navbarType === "sticky",
              "floating-nav":
                this.props.navbarType === "floating" ||
                !["static", "sticky", "floating"].includes(
                  this.props.navbarType
                ),
            }
          )}
        >
          <div className="navbar-container main-menu-content">
            <ul className="nav navbar-nav" id="main-menu-navigation">
              {this.renderDropdown(this.props.newNavigationConfig)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const orderedMenu = _.orderBy(state.sso.menu, ["order", "asc"]);
  return {
    currentUser: state.sso?.userProfile?.role,
    newNavigationConfig: orderedMenu.map((actionGroup) => {
      const children = _.orderBy(actionGroup.actions, ["order", "asc"]).map(
        (action) => {
          return {
            id: action.id,
            title: (
              <FormattedMessage
                id={action.formatMessageId}
                defaultMessage={action.name}
              />
            ),
            newTab: action.newTab ? true : false,
            type: action.type ? action.type : "item",
            icon: <FeatherIcon icon={action.icon ?? "circle"} size="12" />,
            permissions: [],
            navLink: action.navLink,
            visible: action.visible,
          };
        }
      );

      return {
        id: "ag_" + actionGroup.id,
        title: (
          <FormattedMessage
            id={actionGroup.formatMessageId}
            defaultMessage={actionGroup.name}
          />
        ),
        type: "collapse",
        icon: <FeatherIcon icon={actionGroup.icon ?? "circle"} size="20" />,
        children,
      };
    }),
  };
};
export default connect(mapStateToProps)(HorizontalSidebar);
