import classnames from "classnames";
import React from "react";

/**
 * Function: Footer
 * Description: This function generates a footer component with dynamic classes based on the props passed to it.
 * Parameters:
 *   - props: An object containing the properties to customize the footer component.
 * Returns: A React component representing the footer with dynamic classes based on the props.
 */
const Footer = ({footerType}) => {
  let footerTypeArr = ["sticky", "static", "hidden"];
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static":
          footerType === "static" ||
          !footerTypeArr.includes(footerType),
        "d-none": footerType === "hidden",
      })}
    ></footer>
  );
};

export default Footer;
