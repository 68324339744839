import themeConfig from "../../../configs/themeConfig"

/**
 * Function: customizer
 * Description: Reducer function to handle state changes based on different actions.
 * Parameters:
 *    - state: Object - Current state of the application, defaults to themeConfig.
 *    - action: Object - Action object containing type and payload for state changes.
 * Returns:
 *    - Object: New state object based on the action type and payload.
 */
export const customizer = (state = themeConfig, action) => {
    switch (action.type) {
        case "CHANGE_MODE":
            return {...state, theme: action.mode }
        case "COLLAPSE_SIDEBAR":
            return {...state, sidebarCollapsed: action.value }
        case "CHANGE_NAVBAR_COLOR":
            return {...state, navbarColor: action.color }
        case "CHANGE_NAVBAR_TYPE":
            return {...state, navbarType: action.style }
        case "CHANGE_FOOTER_TYPE":
            return {...state, footerType: action.style }
        case "CHANGE_MENU_COLOR":
            return {...state, menuTheme: action.style }
        case "HIDE_SCROLL_TO_TOP":
            return {...state, hideScrollToTop: action.value }
        default:
            return state
    }
}
