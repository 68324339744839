import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import * as Icon from "react-feather";
import { NavItem, NavLink, Navbar } from "reactstrap";
import UserDropdown from "./UserDropdown";

/**
 * Function: ThemeNavbar
 *
 * Description:
 * This function generates a themed navbar component based on the provided props.
 * It renders a Navbar component with dynamic classes based on the navbarColor and navbarType props.
 * The navbar can have different colors and types such as floating, static, sticky, or hidden.
 * It also includes a logo and a NavbarUser component based on the props.
 *
 * @param {Object} props - The props object containing navbarColor, navbarType, horizontal, scrolling, and sidebarVisibility.
 * @returns {JSX.Element} A themed Navbar component.
 */
const ThemeNavbar = (props) => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];

  const notifications = ["Notificación 1", "Notificación 2", "Notificación 3"];

  return (
    <React.Fragment>
      {/* <div className="content-overlay" />
      <div className="header-navbar-shadow" /> */}
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow navbar-custom",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
        style={{ height: '50px !important' }}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
              style={{ height: '70px' }}
            >
              {props.horizontal ? null : (
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className="navbar-nav d-xl-none">
                    <NavItem className="mobile-menu mr-auto">
                      <NavLink
                        className="nav-menu-main menu-toggle hidden-xs is-active"
                        onClick={props.sidebarVisibility}
                      >
                        <Icon.Menu className="ficon" />
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
              )}

              <div className="d-flex justify-content-center">
                {/* <NavbarNotifications notifications={notifications} /> */}
                <UserDropdown />
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.sso.userProfile,
});

export default connect(mapStateToProps)(ThemeNavbar);
