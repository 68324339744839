import classnames from "classnames";
import FeatherIcon from "feather-icons-react";
import _ from "lodash";
import React from "react";
import { ChevronRight } from "react-feather";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import SideMenuGroup from "./sideMenuGroup";

/**
 * Componente para renderizar el contenido del menú lateral con grupos colapsables e ítems.
 */
class SideMenuContent extends React.Component {
  constructor(props) {
    super(props);

    this.parentArr = [];
    this.collapsedPath = null;
  }

  state = {
    flag: true,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
    activeItem: null, // Añadido para rastrear el ítem activo
    isSidebarOpen: true, // Estado para rastrear si la barra lateral está abierta o colapsada
  };

  handleGroupClick = (id, parent = null, type = "") => {
    let open_group = this.state.activeGroups;
    let active_group = this.state.currentActiveGroup;
    let temp_arr = this.state.tempArr;

    if (type === "item" && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === "item" && parent !== null) {
      active_group = [];
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [];
        temp_arr.push(parent);
      }
      active_group = temp_arr.slice(0);
    } else if (type === "collapse" && parent === null) {
      temp_arr = [];
      temp_arr.push(id);
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0);
      }
      if (temp_arr.includes(id)) {
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      temp_arr = [];
    }

    if (type === "collapse") {
      if (!open_group.includes(id)) {
        let temp = open_group.filter(function (obj) {
          return active_group.indexOf(obj) === -1;
        });
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function (obj) {
            return !temp.includes(obj);
          });
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0);
        }
        if (!open_group.includes(id)) {
          open_group.push(id);
        }
      } else {
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0);
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group,
      activeItem: id, // Actualizar el ítem activo
    });
  };

  initRender = (parentArr) => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false,
    });
  };

  componentDidMount() {
    const currentItem = this.props.newNavigationConfig.find(
      (item) => item.navLink === this.props.activePath
    );
    this.setState({
      activeItem: currentItem ? currentItem.id : null,
    });
    this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths);
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      );
    }

    // Actualizar el estado isSidebarOpen si la propiedad collapsed cambia
    if (prevProps.collapsed !== this.props.collapsed) {
      this.setState({ isSidebarOpen: !this.props.collapsed });
    }
  }

  render() {
    const { isSidebarOpen } = this.state;
    const menuItems = this.props.newNavigationConfig.map((item) => {
      const CustomAnchorTag = item.type === "external-link" ? `a` : Link;

      if (item.type === "groupHeader") {
        return (
          <li
            className="navigation-header"
            key={`group-header-${item.groupTitle}`}
          >
            <span>{item.groupTitle}</span>
          </li>
        );
      }

      let renderItem = (
        <li
          className={classnames("nav-item", {
            open: this.state.activeGroups.includes(item.id),
            "sidebar-group-active":
              this.state.currentActiveGroup.includes(item.id) ||
              this.state.activeItem === item.id,
            hover: this.props.hoverIndex === item.id,
            active: this.state.activeItem === item.id,
            disabled: item.disabled,
          })}
          key={item.id}
          onClick={(e) => {
            e.stopPropagation();
            if (item.type === "item") {
              this.props.handleActiveItem(item.navLink);
              this.setState({ activeItem: item.id });
              if (this.props.deviceWidth <= 100 && item.type === "item") {
                this.props.toggleMenu();
              }
            } else {
              this.handleGroupClick(item.id, null, item.type);
            }
          }}
        >
          <CustomAnchorTag
            to={item.type === "item" ? item.navLink : ""}
            href={item.type === "external-link" ? item.navLink : ""}
            className={`d-flex ${
              item.badgeText
                ? "justify-content-between"
                : "justify-content-start"
            }`}
            onMouseEnter={() => {
              this.props.handleSidebarMouseEnter(item.id);
            }}
            onMouseLeave={() => {
              this.props.handleSidebarMouseEnter(item.id);
            }}
            key={item.id}
            target={item.newTab ? "_blank" : undefined}
          >
            <div className="menu-text">
              {item.icon}
              <span className="menu-item menu-title">{item.title}</span>
            </div>

            {item.badge ? (
              <div className="menu-badge">
                <Badge color={item.badge} className="mr-1" pill>
                  {item.badgeText}
                </Badge>
              </div>
            ) : (
              ""
            )}
            {item.type === "collapse" ? (
              <ChevronRight className="menu-toggle-icon" size={13} />
            ) : (
              ""
            )}
          </CustomAnchorTag>
          {item.type === "collapse" &&
            item.children &&
            item.children.length > 0 && (
              <SideMenuGroup
                group={item}
                handleGroupClick={this.handleGroupClick}
                activeGroup={this.state.activeGroups}
                handleActiveItem={this.props.handleActiveItem}
                activeItemState={window.location.pathname}
                handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
                activePath={this.props.activePath}
                hoverIndex={this.props.hoverIndex}
                parentArr={this.parentArr}
                currentActiveGroup={this.state.currentActiveGroup}
                currentUser={this.props.currentUser}
                collapsedMenuPaths={this.props.collapsedMenuPaths}
                toggleMenu={this.props.toggleMenu}
                deviceWidth={this.props.deviceWidth}
              />
            )}
        </li>
      );

      if (
        item.navLink &&
        item.collapsed !== undefined &&
        item.collapsed === true
      ) {
        this.collapsedPath = item.navLink;
        this.props.collapsedMenuPaths(item.navLink);
      }

      if (
        item.type === "item" ||
        item.type === "external-link" ||
        (item.type === "collapse" &&
          item.permissions &&
          item.permissions.includes(this.props.currentUser)) ||
        item.permissions === undefined
      ) {
        return renderItem;
      }
    });

    return (
      <React.Fragment>
        {menuItems}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const orderedMenu = _.orderBy(state.sso.menu, ["order", "asc"]);
  return {
    newNavigationConfig: orderedMenu.map((actionGroup) => {
      return {
        id: actionGroup.id,
        title: (
          <FormattedMessage
            id={actionGroup.formatMessageId}
            defaultMessage={actionGroup.name}
          />
        ),
        type: "item",
        navLink: actionGroup.navLink,
        icon: <FeatherIcon icon={actionGroup.icon ?? "circle"} size="20" />,
      };
    }),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuContent);
