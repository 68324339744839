import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Toast, ToastBody, ToastHeader } from "reactstrap";

/**
 * Component representing a job toast notification.
 *
 * This component displays a toast notification for a job, showing its current status and providing actions to interact with it.
 *
 * @param {Function} updateActualJob - Function to update the current job status.
 * @param {Object} actualJob - Object containing information about the current job.
 * @returns {JSX.Element} A toast notification component for the current job.
 */
const Jobs = ({ updateActualJob, actualJob }) => {
  const [visible, setVisible] = React.useState(true);
  const [interval, setInt] = React.useState(null);
  useEffect(() => {
    setInt(setInterval(() => updateActualJob(actualJob.jobId), 2000));
    return () => clearInterval(interval);
  }, [actualJob.jobId]);

  useEffect(() => {
    if (actualJob?.JOB_ESTADO !== "Estado procesando") {
      clearInterval(interval);
      setVisible(true);
    }
  }, [actualJob]);

  if (!actualJob?.jobId) return <></>;

  if (!visible) {
    return <></>;
  }

  const close = (
    <button
      type="button"
      onClick={() => {
        // setVisible(false);
      }}
      className="ml-1 close"
    >
      <span>×</span>
    </button>
  );
  const redirect = () => {
    if (actualJob?.JOB_ESTADO === 22) return; //Estado procesando
    if (actualJob?.JOB_ESTADO === 24) {
      //Estado error
      window.location.href = "../rendiciones/";
    } else {
      window.location.href = "../rendiciones/show/" + actualJob?.jobResultado;
    }
  };

  return (
    <Toast className="job-toast ">
      <ToastHeader className="bg-primary" close={close}>
        Titulo de ejemplo
      </ToastHeader>
      <ToastBody onClick={redirect}>
        {actualJob?.JOB_ESTADO === 22 ? (
          <p>Se está procesando un ejemplo(tarea: #{actualJob?.jobId})</p>
        ) : actualJob?.JOB_ESTADO === 23 ? (
          <>
            {" "}
            <p>Se generó el ejemplo #{actualJob.jobResultado} </p>
            <a
              href={
                "../example" //Route to redirect
              }
            >
              Ir a ver
            </a>
          </>
        ) : actualJob?.JOB_ESTADO === 24 ? (
          <>
            <p>Falló la generación del ejemplo #{actualJob.jobResultado}.</p>
            <a href={"../rendiciones/"}>
              {" "}
              {/* Route to redirect */}
              Ir a ver
            </a>
          </>
        ) : (
          "Estamos trabajando en el ejemplo"
        )}
      </ToastBody>
    </Toast>
  );
};

const mapStateToProps = (state) => {
  return {
    actualJob: state.currentJob.actualJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateActualJob: (id) => dispatch.currentJob.updateActualJob(id),
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
