import { store } from "../index";

const ENTRYPOINT = window.config.apiBase.baseUrl;
const MIME_TYPE = "application/json";

/**
 * Perform a fetch request to the specified URL with the provided options.
 * If headers are not provided in the options, default headers with 'Accept' and 'Content-Type' set to 'application/json' will be used.
 * The Authorization header will be set with the Bearer token from the Redux store state.
 * If the response is not successful (status other than 2xx), it will handle 401 unauthorized status by clearing local storage and reloading the page.
 * If the response contains an error message in JSON format, it will throw an Error with the error details.
 * If the response cannot be parsed as JSON, it will throw a generic error message.
 *
 * @param {string} id - The URL to fetch data from.
 * @param {object} options - Additional options for the fetch request (e.g., method, body, headers).
 * @returns {Promise<Response>} A Promise that resolves to the Response to the fetch request.
 */
export function fetch(id, options = {}) {
  if ("undefined" === typeof options.headers) options.headers = new Headers();
  if (null === options.headers.get("Accept"))
    options.headers.set("Accept", MIME_TYPE);

  if (
    "undefined" !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get("Content-Type")
  )
    options.headers.set("Content-Type", MIME_TYPE);
  options.headers.set("Authorization", "Bearer " + store.getState().sso.token);

  return global.fetch(new URL(id, ENTRYPOINT), options).then((response) => {
    if (response.ok) return response;

    if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    return response.json().then(
      (json) => {
        throw new Error(
          `${json.error.statusCode} - ${json.error.name} - ${json.error.message}`
        );
      },
      () => {
        throw new Error(`An error has occured`);
      }
    );
  });
}
