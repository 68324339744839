import classnames from "classnames";
import React from "react";

/**
 * RadioVuexy component represents a custom radio button component in the Vuexy theme.
 * It extends React.Component to leverage React features.
 *
 * Props:
 * - className: Additional classes to be applied to the radio button container.
 * - color: Color variant for the radio button.
 * - defaultChecked: Boolean value to set the default checked state of the radio button.
 * - value: Value of the radio button.
 * - disabled: Boolean value to disable the radio button.
 * - name: Name attribute for the radio button.
 * - onClick: Function to be called when the radio button is clicked.
 * - onChange: Function to be called when the radio button value changes.
 * - ref: Reference to the radio button element.
 * - checked: Boolean value to set the checked state of the radio button.
 * - size: Size variant for the radio button ('sm' or 'lg').
 * - label: Label text to be displayed next to the radio button.
 */
class RadioVuexy extends React.Component {
  render() {
    return (
      <div
        className={classnames(
          `vx-radio-con ${this.props.className} vx-radio-${this.props.color}`
        )}
      >
        <input
          type="radio"
          defaultChecked={this.props.defaultChecked}
          value={this.props.value}
          disabled={this.props.disabled}
          name={this.props.name}
          onClick={this.props.onClick}
          onChange={this.props.onChange}
          ref={this.props.ref}
          checked={this.props.checked}
        />
        <span
          className={classnames("vx-radio", {
            "vx-radio-sm": this.props.size === "sm",
            "vx-radio-lg": this.props.size === "lg",
          })}
        >
          <span className="vx-radio--border" />
          <span className="vx-radio--circle" />
        </span>
        <span>{this.props.label}</span>
      </div>
    );
  }
}
export default RadioVuexy;
