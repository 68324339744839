import React from "react";
import { IntlProvider } from "react-intl";
import messages_en from "../../assets/data/locales/en.json";
import messages_es from "../../assets/data/locales/es.json";

const menu_messages = {
  en: messages_en,
  es: messages_es,
};

const Context = React.createContext();

/**
 * Component that wraps the IntlProvider from react-intl to provide internationalization capabilities.
 * It manages the locale and messages based on the selected language and allows switching between languages.
 *
 * Props:
 *   - children: ReactNode - The child components to be wrapped by the IntlProvider.
 *
 * State:
 *   - locale: string - The current locale/language being used.
 *   - messages: object - The messages object for the current locale.
 *
 * Context:
 *   - state: object - The current state object containing locale and messages.
 *   - switchLanguage: function - A function to switch the language/locale and update messages accordingly.
 *
 * Usage:
 * ```javascript
 * <IntlProviderWrapper>
 *   <App />
 * </IntlProviderWrapper>
 * ```
 */
class IntlProviderWrapper extends React.Component {
  state = {
    locale: "es",
    messages: menu_messages["es"],
  };

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: (language) => {
            this.setState({
              locale: language,
              messages: menu_messages[language],
            });
          },
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="es"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { Context as IntlContext, IntlProviderWrapper };
