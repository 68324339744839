import { fetch } from "../utils/dataAccess";

/**
 * Function that creates a model for managing the current job state.
 *
 * @param {string} name - The name of the model (default is "currentJob")
 * @returns {object} An object containing the current job model with state, reducers, and effects
 */
export const currentJobModel = (name = "currentJob") => {
  const data = {
    name: name,
    state: {
      actualJob: null,
    },
    reducers: {
      setActualJob(state, job) {
        return Object.assign({}, { ...state, actualJob: job });
      },
      emptyActualJob(state) {
        return Object.assign({}, { ...state, actualJob: null });
      },
    },
    effects: (dispatch) => ({
      async updateActualJob(payload, state) {
        return new Promise((resolve, reject) => {
          fetch("jobs/" + payload)
            .then((response) => response.json())
            .then((retrieved) => {
              this.setActualJob(retrieved);
            })
            .catch((e) => {});
        });
      },
    }),
  };
  return data;
};
