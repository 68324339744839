import classnames from "classnames";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Customizer from "../components/@vuexy/customizer/customizer";
import {
  changeFooterType,
  changeMenuColor,
  changeMode,
  changeNavbarColor,
  changeNavbarType,
  hideScrollToTop,
} from "../redux/actions/customizer/index";
import Footer from "./components/footer/footer";
import Sidebar from "./components/menu/horizontal-menu/horizontalMenu";
import Navbar from "./components/navbar/navbar";

/**
 * Represents a horizontal layout component that manages the layout and customization settings.
 * Extends PureComponent for optimized rendering performance.
 *
 * @property {number} width - The width of the window.
 * @property {boolean} collapsedContent - Flag to indicate if content is collapsed.
 * @property {boolean} customizer - Flag to indicate if customizer is open.
 *
 * @method updateWidth - Updates the width state based on window.innerWidth.
 * @method updateScroll - Updates the scroll state based on window.scrollY.
 * @method handleCustomizer - Handles the opening and closing of the customizer.
 * @method handleRouteChange - Handles route changes by resetting collapsedContent state.
 *
 * @method componentDidMount - Lifecycle method to add event listeners for window resize and scroll, and apply theme classes to the body.
 * @method componentDidUpdate - Lifecycle method to handle changes after component updates, such as applying theme classes to the body.
 *
 * @method render - Renders the horizontal layout component with sidebar, navbar, content, and footer based on customizer settings.
 */
class HorizontalLayout extends PureComponent {
  state = {
    width: window.innerWidth,
    collapsedContent: false,
    customizer: false,
  };

  updateWidth = () => {
    this.setState((prevState) => ({
      width: window.innerWidth,
    }));
  };

  updateScroll = () => {
    this.setState({ scroll: window.scrollY });
  };

  handleCustomizer = (bool) => {
    this.setState({
      customizer: bool,
    });
  };

  handleRouteChange = () => {
    this.setState({
      collapsedContent: false,
    });
  };

  componentDidMount() {
    if (window !== "undefined") {
      window.addEventListener("resize", this.updateWidth, false);
      window.addEventListener("scroll", this.updateScroll, false);
    }

    let layout = this.props.app.customizer.theme;
    return layout === "dark"
      ? document.body.classList.add("dark-layout")
      : layout === "semi-dark"
      ? document.body.classList.add("semi-dark-layout")
      : null;
  }

  componentDidUpdate() {
    this.handleRouteChange();

    let layout = this.props.app.customizer.theme;
    if (layout === "dark") {
      document.body.classList.remove("semi-dark-layout");
      document.body.classList.add("dark-layout");
    } else if (layout === "semi-dark") {
      document.body.classList.remove("dark-layout");
      document.body.classList.add("semi-dark-layout");
    } else {
      return document.body.classList.remove("dark-layout", "semi-dark-layout");
    }
  }

  render() {
    let customizerProps = this.props.app.customizer;
    let navbarTypeArr = ["sticky", "static", "sticky", "floating", "hidden"];
    let menuThemeArr = [
      "primary",
      "success",
      "danger",
      "info",
      "warning",
      "dark",
    ];
    return (
      <div
        className={classnames(
          `wrapper horizontal-layout theme-${customizerProps.menuTheme}`,
          {
            "menu-collapsed":
              this.state.collapsedContent === true && this.state.width > 1200,
            "fixed-footer": customizerProps.footerType === "sticky",
            "navbar-static": customizerProps.navbarType === "static",
            "navbar-sticky": customizerProps.navbarType === "sticky",
            "navbar-floating":
              customizerProps.navbarType === "floating" ||
              !navbarTypeArr.includes(customizerProps.navbarType),
            "navbar-hidden": customizerProps.navbarType === "hidden",
            "theme-primary": !menuThemeArr.includes(customizerProps.menuTheme),
          }
        )}
      >
        <Sidebar
          activePath={window.location.pathname}
          navbarType={customizerProps.navbarType}
        />
        <div className={classnames("app-content content")}>
          <Navbar
            horizontal
            scrolling={this.state.scroll > 50 ? true : false}
            sidebarVisibility={() => {}}
            navbarColor={customizerProps.navbarColor}
            navbarType={customizerProps.navbarType}
          />
          <div className="content-wrapper">{this.props.children}</div>
        </div>

        <Footer footerType={customizerProps.footerType} />
        {customizerProps.disableCustomizer !== true ? (
          <Customizer
            scrollToTop={customizerProps.hideScrollToTop}
            activeNavbar={customizerProps.navbarColor}
            activeMode={customizerProps.theme}
            navbarType={customizerProps.navbarType}
            footerType={customizerProps.footerType}
            menuTheme={customizerProps.menuTheme}
            customizerState={this.state.customizer}
            handleCustomizer={this.handleCustomizer}
            changeNavbar={this.props.changeNavbarColor}
            changeNavbarType={this.props.changeNavbarType}
            changeFooterType={this.props.changeFooterType}
            changeMenuTheme={this.props.changeMenuColor}
            hideScrollToTop={this.props.hideScrollToTop}
            changeMode={this.props.changeMode}
          />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.customizer,
  };
};
export default connect(mapStateToProps, {
  changeNavbarColor,
  changeNavbarType,
  changeFooterType,
  changeMenuColor,
  hideScrollToTop,
  changeMode,
})(HorizontalLayout);
