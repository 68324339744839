import { init } from "@rematch/core";
import persistPlugin, { getPersistor } from "@rematch/persist";
import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import Spinner from "./components/@vuexy/spinner/fallback-spinner";
import Jobs from "./components/ceek/jobs";
import "./index.scss";
import * as models from "./redux";
import * as serviceWorker from "./serviceWorker";
import { IntlProviderWrapper } from "./utility/context/internationalization";
import { Layout } from "./utility/context/layout";
import customizerReducer from "./redux/reducers/customizer";

/**
 * Main component of the application
 */
const LazyApp = lazy(() => import("./App"));

/**
 * Configuration for data persistence in Redux
 */
const configs = {
  storage: storage,
  key: "application",
};

/**
 * Initialization of the Redux store with Rematch
 */
export const store = init({
  models,
  redux: {
    devtoolOptions: {
      disabled: false,
    },
    reducers: {
      customizer: customizerReducer,
    },
    middlewares: [thunk],
  },
  // @ts-ignore
  plugins: [persistPlugin(configs)],
});

/**
 * Gets the persistor for the persistence of the state
 */
const persistor = getPersistor();
const domNode = document.getElementById("root");

/**
 * Rendering of the application
 */
if (domNode) {
  const root = createRoot(domNode);
  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<Spinner />}>
          <Layout>
            <IntlProviderWrapper>
              <LazyApp />
            </IntlProviderWrapper>
          </Layout>
          <Jobs />
        </Suspense>
      </PersistGate>
    </Provider>
  );
} else {
  console.error("No se encontró el elemento con id 'root'");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
