// React Imports
import { useRef, useState } from 'react'

// MUI Imports
import { styled } from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@mui/material/MenuList'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

// Styled component for badge content
const BadgeContentSpan = styled('span')({
    width: 8,
    height: 8,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: 'var(--mui-palette-success-main)',
    boxShadow: '0 0 0 2px var(--mui-palette-background-paper)'
})

const UserDropdown = () => {
    // States
    const [open, setOpen] = useState(false)

    // Refs
    const anchorRef = useRef(null)

    // Hardcoded settings
    const settings = {
        skin: 'bordered' // Puedes cambiarlo a 'bordered' o 'default' según tu necesidad
    }

    const handleDropdownOpen = () => {
        !open ? setOpen(true) : setOpen(false)
    }

    const handleDropdownClose = (event, url) => {
        if (url) {
            window.location.href = url
        }

        if (anchorRef.current && anchorRef.current.contains(event?.target)) {
            return
        }

        setOpen(false)
    }

    const handleUserLogout = async () => {
        // Redirect to login page
        window.location.href = '/login'
    }

    return (
        <>
            <Badge
                ref={anchorRef}
                overlap='circular'
                badgeContent={<BadgeContentSpan onClick={handleDropdownOpen} />}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                className='mis-2'
            >
                <Avatar
                    ref={anchorRef}
                    alt='John Doe'
                    src='/images/avatars/1.png'
                    onClick={handleDropdownOpen}
                    className='cursor-pointer bs-[38px] is-[38px]'
                />
            </Badge>
            <Popper
                open={open}
                transition
                disablePortal
                placement='bottom-end'
                anchorEl={anchorRef.current}
                className='min-is-[240px] !mbs-3 z-[1]'
            >
                {({ TransitionProps, placement }) => (
                    <Fade
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom-end' ? 'right top' : 'left top'
                        }}
                    >
                        <Paper className={settings.skin === 'bordered' ? 'border shadow-none' : 'shadow-lg'}>
                            <ClickAwayListener onClickAway={e => handleDropdownClose(e)}>
                                <MenuList>
                                    <div className='flex items-center plb-2 pli-6 gap-2' tabIndex={-1}>
                                        <Avatar alt='John Doe' src='/images/avatars/1.png' />
                                        <div className='flex items-start flex-col'>
                                            <Typography className='font-medium' color='text.primary'>
                                                John Doe
                                            </Typography>
                                            <Typography variant='caption'>admin@vuexy.com</Typography>
                                        </div>
                                    </div>
                                    <Divider className='mlb-1' />
                                    <MenuItem className='mli-2 gap-3' onClick={e => handleDropdownClose(e)}>
                                        <i className='tabler-user' />
                                        <Typography color='text.primary'>My Profile</Typography>
                                    </MenuItem>
                                    <MenuItem className='mli-2 gap-3' onClick={e => handleDropdownClose(e, '/settings')}>
                                        <i className='tabler-settings' />
                                        <Typography color='text.primary'>Settings</Typography>
                                    </MenuItem>
                                    <MenuItem className='mli-2 gap-3' onClick={e => handleDropdownClose(e, '/pricing')}>
                                        <i className='tabler-currency-dollar' />
                                        <Typography color='text.primary'>Pricing</Typography>
                                    </MenuItem>
                                    <MenuItem className='mli-2 gap-3' onClick={e => handleDropdownClose(e, '/faq')}>
                                        <i className='tabler-help-circle' />
                                        <Typography color='text.primary'>FAQ</Typography>
                                    </MenuItem>
                                    <div className='flex items-center plb-2 pli-3'>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='error'
                                            size='small'
                                            endIcon={<i className='tabler-logout' />}
                                            onClick={handleUserLogout}
                                            sx={{ '& .MuiButton-endIcon': { marginInlineStart: 1.5 } }}
                                        >
                                            Logout
                                        </Button>
                                    </div>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    )
}

export default UserDropdown
