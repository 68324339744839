import React from "react";
import { connect } from "react-redux";
import JobsToast from "./jobsToast";

/**
 * Functional component that renders a specific job based on the actualJob object.
 * If the actualJob object has a jobId property, it renders a JobsToast component.
 * If the actualJob object has a jobId and jobTipo property equal to "Example", it renders a JobsToast component.
 * Otherwise, it renders nothing.
 *
 * @param {Object} actualJob - The actual job object containing job details.
 * @returns {JSX.Element} React element representing the rendered job component.
 */
const Jobs = ({ actualJob }) => {
  if (!actualJob?.jobId) return <></>;

  if (actualJob?.jobId && actualJob?.jobTipo === "Example") {
    return <JobsToast />;
  }

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    actualJob: state.currentJob.actualJob,
  };
};

export default connect(mapStateToProps, null)(Jobs);
