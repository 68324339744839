import React from "react";
import logo from "../../../assets/img/logo/logo_empresa.png";

/**
 * Component representing a fallback spinner for loading state.
 * Renders a spinner with a company logo, loading animation, and loading message.
 */
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100 d-flex justify-content-center">
        <div className="d-flex justify-content-center flex-column">
          <img className="logoEmpresa" src={logo} height="150px" alt="logo" />
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p style={{ background: "#eee", padding: "10px 15px" }}>
            Estamos cargando la información...
          </p>
        </div>
      </div>
    );
  }
}

export default SpinnerComponent;
