import { fetch } from "../utils/dataAccess";
import nameToUrls from "./nameToUrls";

const initialState = {
  error: null,
  loading: false,
  retrieved: null,
  edit: false,
  selected: true,
};

/**
 * Function that generates a model object for a given name, containing state, reducers, and effects for CRUD operations.
 *
 * @param {string} name - The name used to generate the model object.
 * @returns {Object} - The model object containing state, reducers, and effects for CRUD operations.
 */
export const makeModel = (name) => {
  const data = {
    name: name,
    state: {
      create: initialState,
      list: initialState,
      del: initialState,
      show: initialState,
      update: initialState,
      options: initialState,
      filter: "",
    },
    reducers: {
      setCreateError(state, error) {
        return Object.assign(
          {},
          { ...state, create: { ...state.create, error } }
        );
      },
      setCreateLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, create: { ...state.create, loading } }
        );
      },
      setCreated(state, retrieved) {
        return Object.assign(
          {},
          { ...state, create: { ...state.create, retrieved } }
        );
      },
      createReset(state) {
        return Object.assign({}, { ...state, create: initialState });
      },
      setListError(state, error) {
        return Object.assign({}, { ...state, list: { ...state.list, error } });
      },
      setListLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, list: { ...state.list, loading } }
        );
      },
      setListed(state, retrieved) {
        return Object.assign(
          {},
          { ...state, list: { ...state.list, retrieved } }
        );
      },
      listReset(state) {
        return Object.assign({}, { ...state, list: initialState });
      },
      setDeleteError(state, error) {
        return Object.assign({}, { ...state, del: { ...state.del, error } });
      },
      setDeleteLoading(state, loading) {
        return Object.assign({}, { ...state, del: { ...state.del, loading } });
      },
      setDeleted(state, retrieved) {
        return Object.assign(
          {},
          { ...state, del: { ...state.del, retrieved } }
        );
      },
      deleteReset(state) {
        return Object.assign({}, { ...state, del: initialState });
      },
      setShowError(state, error) {
        return Object.assign({}, { ...state, show: { ...state.show, error } });
      },
      setShowLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, show: { ...state.show, loading } }
        );
      },
      setShowed(state, retrieved) {
        return Object.assign(
          {},
          { ...state, show: { ...state.show, retrieved } }
        );
      },
      showReset(state) {
        return Object.assign({}, { ...state, show: initialState });
      },
      setUpdateError(state, error) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, error } }
        );
      },
      setUpdateLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, loading } }
        );
      },
      setUpdated(state, retrieved) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, retrieved } }
        );
      },
      setListEdit(state, edit) {
        return Object.assign({}, { ...state, list: { ...state.list, edit } });
      },
      setUpdateEdit(state, edit) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, edit } }
        );
      },
      setListSelected(state, selected) {
        return Object.assign(
          {},
          { ...state, list: { ...state.list, selected } }
        );
      },
      updateReset(state) {
        return Object.assign({}, { ...state, update: initialState });
      },
      setOptionsError(state, error) {
        return Object.assign(
          {},
          { ...state, options: { ...state.options, error } }
        );
      },
      setOptionsLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, options: { ...state.options, loading } }
        );
      },
      setOptions(state, retrieved) {
        return Object.assign(
          {},
          { ...state, options: { ...state.options, retrieved } }
        );
      },
      optionsReset(state) {
        return Object.assign({}, { ...state, options: initialState });
      },

      setFilter(state, filter) {
        return Object.assign({}, { ...state, filter: filter });
      },
    },
    effects: (dispatch) => ({
      async list(payload, state) {
        return new Promise((resolve) => {
          this.setListLoading(true);
          this.setListError(null);

          let url = nameToUrls[name];

          if (payload !== undefined && payload.url !== undefined)
            url += payload.url;

          fetch(url)
            .then((response) => response.json())
            .then((retrieved) => {
              this.setListLoading(false);
              this.setListed(retrieved);
              resolve(retrieved);
            })
            .catch((e) => {
              this.setListLoading(false);
              this.setListError(e.message);
            });
        });
      },
      show(payload, state) {
        return new Promise((resolve) => {
          this.setShowLoading(true);
          this.setShowError(null);
          let url = nameToUrls[name] + payload.id;
          if (payload !== undefined && payload.url !== undefined)
            url += payload.url;

          fetch(url)
            .then((response) => response.json())
            .then((retrieved) => {
              this.setShowLoading(false);
              this.setShowed(retrieved);
              resolve(retrieved);
            })
            .catch((e) => {
              this.setShowLoading(false);
              this.setShowError(e.message);
            });
        });
      },
      create(payload, state) {
        return new Promise((resolve) => {
          this.setCreateLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);

          this.setCreateError(null);

          fetch(nameToUrls[name], {
            method: "POST",
            body: JSON.stringify(payload.values),
          })
            .then((response) => {
              this.setCreateLoading(false);
              response.json().then((retrieved) => {
                this.setCreated(retrieved);
                resolve(retrieved);
              });
              if (payload.parentEntity) {
                dispatch[payload.parentEntity].setShowLoading(false);
              }
            })
            .catch((e) => {
              this.setCreateLoading(false);
              this.setCreateError(e.message);
              if (payload.parentEntity) {
                dispatch[payload.parentEntity].setShowLoading(false);
              }
            });
        });
      },
      update(payload, state) {
        return new Promise((resolve) => {
          this.setUpdateLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);

          this.setUpdateError(null);

          fetch(nameToUrls[name] + payload.item.id, {
            method: "PUT",
            body: JSON.stringify(payload.values),
          })
            .then((response) => {
              this.setUpdateLoading(false);
              response
                .json()
                .then((retrieved) => {
                  this.setUpdated(retrieved);
                  resolve(retrieved);
                })
                .catch(() =>
                  this.setUpdated({ ...payload.item, item: "no item" })
                );
            })

            .catch((e) => {
              this.setUpdateLoading(false);
              this.setUpdateError(e.message);
              if (payload.parentEntity) {
                dispatch[payload.parentEntity].setShowLoading(false);
              }
            });
        });
      },
      async delete(payload, state) {
        return new Promise((resolve) => {
          this.setDeleteLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);

          this.setDeleteError(null);

          fetch(nameToUrls[name] + payload.item.id, {
            method: "DELETE",
          })
            .then((response) => {
              this.setDeleteLoading(false);
              this.setDeleted(payload.item);
              resolve(payload.item);
            })
            .catch((e) => {
              this.setDeleteLoading(false);
              this.setDeleteError(e.message);
            });
        });
      },
    }),
  };
  return data;
};
